import IMask from 'imask';
import lightGallery from 'lightgallery';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import { Modal } from 'bootstrap/js/dist/modal';

import screenfull from 'screenfull';

//import { jarallax } from 'jarallax';
import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

// Инициализация при загрузке DOM-дерева
document.addEventListener('DOMContentLoaded', () => {
  initTelMasks();
  initLightGalleries();
  initSliderFeedbacks();

  //checkScreenSize();

  if (document.querySelector('[data-localvideo]')) {
    const videoEl = document.querySelector('[data-localvideo]');

    videoEl.addEventListener('click', function (e) {
      e.preventDefault();

      screenfull.toggle(videoEl, { navigationUI: 'hide' });
    });

    let isTapped = false;

    videoEl.addEventListener('touchstart', function () {
      isTapped = true;
    });

    videoEl.addEventListener('touchend', function (e) {
      e.preventDefault();

      if (isTapped && !document.fullscreenElement) {
        screenfull.toggle(videoEl, { navigationUI: 'hide' });
      } else if (isTapped && document.fullscreenElement) {
        screenfull.toggle(videoEl, { navigationUI: 'hide' });
      }
      isTapped = false;
    });
  }

  if (document.querySelector('[data-navclose]')) {
    document.querySelectorAll('[data-navclose]').forEach((el) => {
      el.addEventListener('click', closeNav);
    });
  }

  if (document.querySelector('[data-navtoggler]')) {
    document.querySelectorAll('[data-navtoggler]').forEach((el) => {
      el.addEventListener('click', toggleNav);
    });
  }

  if (document.querySelector('[data-nav]')) {
    document
      .querySelector('[data-nav]')
      .addEventListener('click', navClickHandler);
  }

  window.addEventListener('resize', function () {
    //checkScreenSize();
  });

  const sliderObjects = new Swiper('.slider-objects', {
    loop: true,
    watchSlidesProgress: true,

    navigation: {
      nextEl: '.swiper-button-next',
      //prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      1200: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1400: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1600: {
        slidesPerView: 2,
        spaceBetween: 60,
      },
    },
  });
});

function checkScreenSize() {
  if (window.innerWidth > 992) {
    jarallax(document.querySelectorAll('.jarallax'), {});
  } else {
    jarallax(document.querySelectorAll('.jarallax'), 'destroy');
  }
}

// Функция инициализации масок телефонных номеров
function initTelMasks() {
  const telInputs = document.querySelectorAll('input[type=tel]');
  telInputs.forEach((el) => {
    IMask(el, {
      mask: '+{7} 000 000 00 00',
    });
  });
}

// Функция инициализации галерей изображений
function initLightGalleries() {
  const galleryElements = document.querySelectorAll('[data-gallery]');
  galleryElements.forEach((el) => {
    lightGallery(el, {
      licenseKey: '0000 0000 0000 0000',
      download: false,
      fullScreen: false,
      counter: false,
      plugins: [lgFullscreen, lgVideo],
      selector: '[data-src]',
    });
  });
}

function initSliderFeedbacks() {
  const sliderFeedbacks = new Swiper('.slider-feedbacks', {
    //loop: true,
    spaceBetween: 24,
    initialSlide: 1,
    watchSlidesProgress: true,
    centeredSlides: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 3,
      },
    },
  });
}

function closeNav() {
  document.querySelector('[data-nav]').classList.remove('active');
}

function toggleNav() {
  document.querySelector('[data-nav]').classList.toggle('active');
}

function navClickHandler(e) {
  if (e.target.tagName !== 'A') {
    closeNav();
  }
}

// Экспорт модального окна для доступа извне
window.modal = Modal;

function getRandomNumber() {
  return (Math.random() * 0.3 + 0.1).toFixed(1);
}

/* Параллакс */
document.addEventListener('DOMContentLoaded', function () {
  const columns = document.querySelectorAll('.block-paralax__column');

  function updateParallax() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (windowWidth <= 992) {
      columns.forEach((column) => {
        column.style.transform = 'none'; // Reset transform for smaller screens
      });
      return;
    }

    columns.forEach((column) => {
      const columnRect = column.getBoundingClientRect();
      if (columnRect.top < windowHeight && columnRect.bottom > 0) {
        let speed = column.dataset.paraspeed;
        const scrollPosition = window.scrollY || window.pageYOffset;
        const offset = (columnRect.top - windowHeight) * speed; // Adjust the multiplier to control the speed
        column.style.transform = `translateY(${offset}px)`;
      }
    });
  }

  window.addEventListener('scroll', updateParallax);
  window.addEventListener('resize', updateParallax);
  updateParallax(); // Initial update
});
/* Конец Паралакса */
